import { Injectable } from '@angular/core';
import { z } from 'zod';

@Injectable({
    providedIn: 'root',
})
export class SchemaValidatorService {
    /**
     * Validates an object against a Zod schema.
     * @param schema The Zod schema to validate against.
     * @param data The object to validate.
     * @returns The validated object.
     * @throws ZodError If the object is not valid against the schema.
     */
    validate<TInput, TOutput>(schema: z.ZodType<TOutput, z.ZodTypeDef, TInput>, data: TInput): TOutput {
        try {
            return schema.parse(data);
        } catch (error) {
            if (error instanceof z.ZodError) {
                const msg = error.errors
                    .map((e) => {
                        switch (e.code) {
                            case 'invalid_type':
                                return `at ${e.path.join('/')}: expected ${e.expected} was ${e.received}`;
                            default:
                                return e.message;
                        }
                    })
                    .join('\n');
                console.error(msg);
            }
            throw error;
        }
    }

    /**
     * Validates an array of objects against a Zod schema.
     * @param schema The Zod schema to validate against.
     * @param data The array of objects to validate.
     * @returns The validated array of objects.
     * @throws ZodError If any of the objects are not valid against the schema.
     */
    validateMany<TInput, TOutput>(schema: z.ZodType<TOutput, z.ZodTypeDef, TInput>, data: TInput[]): TOutput[] {
        return this.validate(z.array(schema), data);
    }
}
