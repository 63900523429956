import { computed, inject, Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { z } from 'zod';
import { SchemaValidatorService } from './schema-validator.service';

const RuntimeAppConfigSchema = z.object({
    apiPath: z.string(),
    backendUrl: z.string(),
    sentryEnvironmentName: z.string(),
    enableSentry: z.boolean(),
});
type RuntimeAppConfig = z.infer<typeof RuntimeAppConfigSchema>;

@Injectable({
    providedIn: 'root',
})
export class RuntimeConfigService {
    #http = inject(HttpClient);
    #validator = inject(SchemaValidatorService);
    config = signal<RuntimeAppConfig | undefined>(undefined);
    apiUrl = computed(() => `${this.config()?.backendUrl}${this.config()?.apiPath}`);

    async loadConfig(): Promise<void> {
        try {
            const rawConfig = await firstValueFrom(this.#http.get('/assets/config.json'));
            const validatedConfig = this.#validator.validate(RuntimeAppConfigSchema, rawConfig);
            this.config.set(validatedConfig);
        } catch (error) {
            const err = error as Error;
            throw new Error(`An error occurred while fetching the configuration: ${err.message}`);
        }
    }
}
